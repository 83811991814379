import React from 'react';
import {BrowserRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './containers/Home/Home';
import DownPage from './containers/Home/DownPage';
import Privacy from './containers/Privacy/Privacy';
import DownComp from './containers/Form/DownComp';

export default props => (
  <div>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/download" component={DownPage} />
        <Route exact path="/privacy" component={Privacy} />
        <Route path="/complaints/download" component={DownComp} />
      </Switch>
    </BrowserRouter>
  </div>
);
