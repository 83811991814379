import React from "react";
import { Helmet } from 'react-helmet';

import './privacy.css';

export default function Privacy() {
  return (
    <>
      <div className="home">
        <Helmet>
          <title>Privacy Policy</title>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
        </Helmet>
        <section className="flex flex-col items-center md:items-start px-24 pt-4 mb-10">
          <a href="/">
            <img className="h-16" src="/logo.png" />
          </a>
        </section>
        <section>
          <div className="px-4 md:px-16 flex flex-col mb-96 items-center">
            <h1 className="text-gray-600 text-8xl font-bold mb-16">Privacy Policy</h1>
            <div className="w-160 h-160 overflow-x-scroll border-solid border-eblue-400 border-4 p-6">
              <div className="w-full text-gray-700">
                This Privacy Policy (“Policy”) describes the policies and procedures on the collection, use, disclosure and protection of your information when you use our website located at etark.in, or the ETark mobile application (collectively, “ETark Platform”) made available by ETark Technology Private Limited (“ETark”, “Company”, “we”, “us” and “our”),  a private company established under the laws of India having its registered office at No.13, Mall Enclave, K.B. Sarani, Dum Dum, Kolkata - 700080
                <br/><br/>
                The terms “you” and “your” refer to the user of the ETark Platform. The term “Services” refers to any services offered by ETark whether on the ETark Platform or otherwise.
                <br/><br/>
                Please read this Policy before using the ETark Platform or submitting any personal information to ETark. This Policy is a part of and incorporated within, and is to be read along with, the Terms & Conditions.
                <br/><br/>
                <h1>YOUR CONSENT</h1>
                By using the ETark Platform and the Services, you agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information as described and collected by us in accordance with this Policy.  If you do not agree with the Policy, please do not use or access the ETark Platform.
                <br/><br/>
                <h1>POLICY CHANGES</h1>
                We may occasionally update this Policy and such changes will be posted on this page. If we make any significant changes to this Policy we will endeavour to provide you with reasonable notice of such changes, such as via prominent notice on the ETark Platform or to your email address on record and where required by applicable law, we will obtain your consent. To the extent permitted under the applicable law, your continued use of our Services after we publish or send a notice about our changes to this Policy shall constitute your consent to the updated Policy.
                <br/><br/>
                <h1>LINKS TO OTHER WEBSITES</h1>
                The ETark Platform may contain links to other websites. Any personal information about you collected whilst visiting such websites is not governed by this Policy. ETark shall not be responsible for and has no control over the practices and content of any website accessed using the links contained on the ETark Platform. This Policy shall not apply to any information you may disclose to any of our service providers/service personnel which we do not require you to disclose to us or any of our service providers under this Policy.
                <br/><br/>
                <h1>INFORMATION WE COLLECT FROM YOU</h1>
                We will collect and process the following information about you:
                <br/>
                Information you give us - This includes information submitted when you:
                <br/>
                <ul>
                  <li>Create or update your ETark account, which may include your name, email, phone number, login name and password, payment or banking information, date of birth and profile picture. If you sign in to the ETark Platform through third-party sign-in services such as Facebook, Google Plus or Gmail or any other social networking or similar site (collectively, “SNS”), an option of which may be provided to you by ETark at its sole discretion, you will be allowing us to pass through and receive from the SNS your log-in information and other user data; or</li>
                  <li>Provide content to us, which may include product/service against which you have a problem, the period for which the product/service was used, it’s Bill Number or Order ID, IMEI No. or any other detail required to validate that product, proof of purchase, your complaint against the product/service and other information you provide on the ETark Platform (“Your Content”).</li>
                  <li>Use our Services, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, email, billing information and credit or payment card information.</li>
                  <li>Correspond with ETark for customer support;</li>
                  <li>Participate in the interactive services offered by the ETark Platform such as discussion boards, competitions, promotions or surveys, other social media functions or make payments etc., or</li>
                  <li>Enable features that require ETark's access to your calendar;</li>
                  <li>Report problems for troubleshooting.</li>
                  <li>Your details including transaction history, balances, payment details, for effecting transfer of monies through various payment channels provided by us.</li>
                  <li>Financial details (e.g. income, expenses, and/or credit history) needed as part of request for some of our products/services;</li>
                  <li>Images of documents/ photos required to avail any of our products/services.</li>
                  <li>Voice recordings of our conversations with our customer care agent with you to address your queries/grievances;</li>
                  <li>Specimen signature(s) for processing of your instructions received by us through our various payment and delivery channels;</li>
                  <li>Opinions provided by you to us by way of feedback or responses to surveys;</li>
                  <li>Information obtained from your mobile device by way of using our app like device location, communication information including contacts and call logs, device information (including storage, model, mobile network), transactional and promotional SMS/app notifications.</li>
                </ul>
                <br/>
                Information we collect about you - With regard to each of your visits to the ETark Platform, we will automatically collect and analyse the following demographic and other information:
                <ul>
                  <li>When you communicate with us (via email, phone, through the ETark Platform or otherwise), we may maintain a record of your communication;</li>
                  <li>Location information: Depending on the Services that you use, and your app settings or device permissions, we may collect your real time information, or approximate location information as determined through data such as GPS, IP address;</li>
                  <li>Usage and Preference Information: We collect information as to how you interact with our Services, preferences expressed and settings chosen. ETark Platform includes the ETark marketing services (“Marketing Services”), which may collect user activity and browsing history within the ETark Platform and across third-party sites and online services, including those sites and services that include our ad pixels (“Pixels”), widgets, plug-ins, buttons, or related services or through the use of cookies. Our Marketing Services collect browsing information including without limitation your Internet protocol (IP) address and location, your login information, browser type and version, date and time stamp, user agent, ETark cookie ID (if applicable), time zone setting, browser plug-in types and versions, operating system and platform, and other information about user activities on the ETark Platform, as well as on third party sites and services that have embedded our Pixels, widgets, plug-ins, buttons, or related services;</li>
                  <li>Transaction Information: We collect transaction details related to your use of our Services, and information about your activity on the Services, including the full Uniform Resource Locators (URL), the type of Services you requested or provided, comments, domain names, search results selected, number of clicks, information and pages viewed and searched for, the order of those pages, length of your visit to our Services, the date and time you used the Services, amount charged, details regarding application of promotional code, methods used to browse away from the page and any phone number used to call our customer service number and other related transaction details;</li>
                  <li>Device Information: We may collect information about the devices you use to access our Services, including the hardware models, operating systems and versions, software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion information and mobile network information. Analytics companies may use mobile device IDs to track your usage of the ETark Platform;</li>
                  <li>Stored information and files:  ETark mobile application (ETark app) may also access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts and address book information. If you permit the ETark app to access the address book on your device, we may collect names and contact information from your address book to facilitate social interactions through our services and for other purposes described in this Policy or at the time of consent or collection. If you permit the ETark app to access the calendar on your device, we collect calendar information such as event title and description, your response (Yes, No, Maybe), date and time, location and number of attendees.</li>
                </ul>

                <br/>
                Information we receive from other sources -
                <ul>
                  <li>We may receive information about you from third parties, such as other users, partners (including ad partners, analytics providers, search information providers), or our affiliated companies or if you use any of the other websites/apps we operate or the other Services we provide. Users of our Ad Services and other third-parties may share information with us such as the cookie ID, device ID, or demographic or interest data, and information about content viewed or actions taken on a third-party website, online services or apps. For example, users of our Ad Services may also be able to share customer list information (e.g., email or phone number) with us to create customized audience segments for their ad campaigns.</li>
                  <li>When you sign in to ETark Platform with your SNS account, or otherwise connect to your SNS account with the Services, you consent to our collection, storage, and use, in accordance with this Policy, of the information that you make available to us through the social media interface. This could include, without limitation, any information that you have made public through your social media account, information that the social media service shares with us, or information that is disclosed during the sign-in process.  Please see your social media provider’s privacy policy and help center for more information about how they share information when you choose to connect your account.</li>
                </ul>

                <br/>
                <h1>COOKIES</h1>
                <br/>
                Our ETark Platform and third parties with whom we partner, may use cookies, pixel tags, web beacons, mobile device IDs, “flash cookies” and similar files or technologies to collect and store information with respect to your use of the Services and third-party websites.
                <br/><br/>
                Cookies are small files that are stored on your browser or device by websites, apps, online media and advertisements. We use cookies and similar technologies for purposes such as:
                <ul>
                  <li>Authenticating users;</li>
                  <li>Remembering user preferences and settings;</li>
                  <li>Determining the popularity of content;</li>
                  <li>Delivering and measuring the effectiveness of advertising campaigns;</li>
                  <li>Analysing site traffic and trends, and generally understanding the online behaviours and interests of people who interact with our services.</li>
                </ul>
                <br/>
                A pixel tag (also called a web beacon or clear GIF) is a tiny graphic with a unique identifier, embedded invisibly on a webpage (or an online ad or email), and is used to count or track things like activity on a webpage or ad impressions or clicks, as well as to access cookies stored on users’ computers. We use pixel tags to measure the popularity of our various pages, features and services. We also may include web beacons in e-mail messages or newsletters to determine whether the message has been opened and for other analytics.
                <br/><br/>
                To modify your cookie settings, please visit your browser’s settings. By using our Services with your browser settings to accept cookies, you are consenting to our use of cookies in the manner described in this section.
                <br/><br/>
                We may also allow third parties to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs and other technologies to identify your device when you visit the ETark Platform and use our Services, as well as when you visit other online sites and services.
                <br/><br/>
                Please see our Cookie Policy for more information regarding the use of cookies and other technologies described in this section, including regarding your choices relating to such technologies.
                <br/><br/>
                <h1>USES OF YOUR INFORMATION</h1>
                <br/>
                We use the information we collect for following purposes, including:
                <ul>
                  <li>To provide, personalise, maintain and improve our products and services, such as to enable deliveries of some products or services offered as Offers provided by our Resolution Platform or Partner Company and other services, enable features to personalise your ETark account;</li>
                  <li>To carry out our obligations arising from any contracts entered into between you and us and to provide you with the relevant information and services;</li>
                  <li>To administer and enhance the security of our ETark Platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
                  <li>To provide you with information about services we consider similar to those that you are already using, or have enquired about, or may interest you. If you are a registered user, we will contact you by electronic means (e-mail or SMS or telephone) with information about these services;</li>
                  <li>To understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, make special offers, provide customer support, process and respond to your queries;</li>
                  <li>To generate and review reports and data about, and to conduct research on, our user base and Service usage patterns;</li>
                  <li>To allow you to participate in interactive features of our Services, if any; or</li>
                  <li>To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you.</li>
                  <li>To carry out academic research with academic partners.</li>
                </ul>
                <br/>
                We may combine the information that we receive from third parties with the information you give to us and information we collect about you for the purposes set out above.  Further, we may anonymize and/or de-identify information collected from you through the Services or via other means, including via the use of third-party web analytic tools. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by this Policy, and it may be used and disclosed to others without limitation.
                <br/><br/>
                We analyse the log files of our ETark Platform that may contain Internet Protocol (IP) addresses, browser type and language, Internet service provider (ISP), referring, app crashes, page viewed and exit websites and applications, operating system, date/time stamp, and clickstream data. This helps us to administer the website, to learn about user behavior on the site, to improve our product and services, and to gather demographic information about our user base as a whole.
                <br/><br/>
                <h1>DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION</h1>
                <br/>
                We may share your information that we collect for following purposes:
                <ul>
                  <li>With Our Partners: We may share your information with our vendors, consultants, marketing partners, Legal Aid Partners, research firms and other service providers or business partners, such as Payment processing companies, to support our business. For example, your information may be shared with outside vendors to send you emails and messages or push notifications to your devices in relation to our Services, to help us analyze and improve the use of our Services, to process and collect payments. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us.</li>
                  <li>With Legal Aid Partners: While you place a request to resolve your complaint through the ETark Platform, your information is provided to us and to the Legal Aid Partners with whom you may choose to do the resolution. In order to facilitate your online complaint resolution, we provide your information to that Legal Aid Partner in a similar manner as if you had placed a complaint resolution request directly with the Legal Aid Partner. If you provide a mobile phone number, ETark may send you text messages regarding the complaint resolution process.</li>
                  <li>With Academic Partners: We may share your information with our academic partners for the purpose of carrying out academic research.</li>
                  <li>For Crime Prevention or Investigation: We may share this information with governmental agencies or other companies assisting us, when we are:
                    <li>Obligated under the applicable laws or in good faith to respond to court orders and processes; or</li>
                    <li>Detecting and preventing against actual or potential occurrence of identity theft, fraud, abuse of Services and other illegal acts;</li>
                    <li>Responding to claims that an advertisement, posting or other content violates the intellectual property rights of a third party;</li>
                    <li>Under a duty to disclose or share your personal data in order to enforce our Terms & Conditions and other agreements, policies or to protect the rights, property, or safety of the Company, our customers, or others, or in the event of a claim or dispute relating to your use of our Services. This includes exchanging information with other companies and organisations for the purposes of fraud detection and credit risk reduction.</li>
                  </li>
                  <li>For Internal Use: We may share your information with any present or future member of our “Group” (as defined below)or affiliates for our internal business purposes The term “Group” means, with respect to any person, any entity that is controlled by such person, or any entity that controls such person, or any entity that is under common control with such person, whether directly or indirectly, or, in the case of a natural person, any Relative (as such term is defined in the Companies Act, 1956 and Companies Act, 2013 to the extent applicable) of such person.</li>
                  <li>With Advertisers and advertising networks: We may work with third parties such as network advertisers to serve advertisements on the ETark Platform and on third-party websites or other media (e.g., social networking platforms). These third parties may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you.</li>
                  <li>While you cannot opt out of advertising on the ETark Platform, you may opt out of much interest-based advertising on third party sites and through third party ad networks (including DoubleClick Ad Exchange, Facebook Audience Network and Google AdSense). For more information, visit www.aboutads.info/choices. Opting out means that you will no longer receive personalized ads by third parties ad networks from which you have opted out, which is based on your browsing information across multiple sites and online services. If you delete cookies or change devices, your opt out may no longer be effective.</li>
                  <li>To fulfill the purpose for which you provide it.</li>
                  <li>We may share your information other than as described in this Policy if we notify you and you consent to the sharing.</li>
                </ul>
                <br/>
                <h1>DATA SECURITY PRECAUTIONS</h1>
                <br/>
                We have in place appropriate technical and security measures to secure the information collected by us.
                <br/><br/>
                We use vault and tokenization services from third party service providers to protect the sensitive personal information provided by you. The third-party service providers with respect to our vault and tokenization services and our payment gateway and payment processing are compliant with the payment card industry standard (generally referred to as PCI compliant service providers). You are advised not to send your full credit/debit card details through unencrypted electronic platforms. Where we have given you (or where you have chosen) a username and password which enables you to access certain parts of the ETark Platform, you are responsible for keeping these details confidential. We ask you not to share your password with anyone.
                <br/><br/>
                Please be aware that the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted through the ETark Platform. Once we have received your information, we will use strict physical, electronic, and procedural safeguards to try to prevent unauthorised access.
                <br/><br/>
                <h1>OPT-OUT</h1>
                <br/>
                When you sign up for an account, you are opting in to receive emails from ETark. You can log in to manage your email preferences or you can follow the “unsubscribe” instructions in commercial email messages, but note that you cannot opt out of receiving certain administrative notices, service notices, or legal notices from ETark.
                <br/><br/>
                If you wish to withdraw your consent for the use and disclosure of your personal information in the manner provided in this Policy, please write to us at contact@etark.in. Please note that we may take time to process such requests, and your request shall take effect no later than 5 (Five) business days from the receipt of such request, after which we will not use your personal data for any processing unless required by us to comply with our legal obligations. We may not be able offer you any or all Services upon such withdrawal of your consent.
                <br/><br/>
                <h1>GRIEVANCE OFFICER AND ETark PLATFORM SECURITY</h1>
                <br/>
                If you have any queries relating to the processing or usage of information provided by you in connection with this Policy, please email us at contact@etark.in
                <br/><br/>
                If you come across any abuse or violation of the Policy, please report to contact@etark.in
                <br/><br/>
                Further, please note that the ETark Platform stores your data with the cloud platform of Amazon Web Services provided by Amazon Web Services, Inc.,
                which may store this data on their servers located outside of India. Amazon Web Services has security measures in place to protect the loss, misuse and alteration of the information, details of which are available at  <a className="text-eblue-800" href="https://aws.amazon.com/">https://aws.amazon.com/</a>.
                <br/><br/>
                The privacy policy adopted by Amazon Web Services is detailed in <a className="text-eblue-800" href="https://aws.amazon.com/privacy">https://aws.amazon.com/privacy</a>.
                <br/><br/>
                In the event you have questions or concerns about the security measures adopted by Amazon Web Services, you can contact their data protection / privacy team / legal team or designated the grievance officer at these organisations, whose contact details are available in its privacy policy, or can also write to our Grievance Officer at the email ID provided above.
              </div>
            </div>
          </div>
        </section>
        <section className="hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="barsvg"
          >
            <path
              fill="#2E3236"
              fillOpacity={1}
              style={{ transform: "translateY(-550px)" }}
              d="M445 756C115.368 690.232 -13.6981 763.805 -253.441 895.666L2868.18 872.975L2716 -0.142107C2632.82 0.121105 2585.47 7.0036 2505.66 30.4313C2276.35 97.7426 2233.72 429.158 2020.48 430.154C1807.24 431.149 1399 168 1347 482C1295 796 774.632 821.768 445 756Z"
            />
          </svg>
        </section>
        <section className="hidden md: text-gray-600 body-font down-section">
          <div className="flex flex-col items-center w-full py-2 leading-relaxed">
            <div className="text-gray-300 font-medium text-xl text-center mt-12 mb-8">Follow Us</div>
            <div className="flex items-center">
              <a href="https://www.facebook.com/etarkcompany" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/facebook.png"/>
              </a>
              <a href="http://linkedin.com/company/etark" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/linkedin.png"/>
              </a>
              <a href="https://twitter.com/ETark4" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/twitter.png"/>
              </a>
              <a href="https://instagram.com/etark_official" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/instagram.png"/>
              </a>
            </div>
            <div className="flex mt-8 mb-4 text-gray-400">
              <a href="/privacy">Privacy Policy</a>
            </div>
            <div className="text-gray-400 font-medium text-base text-center mb-4">&copy; Copyright 2021. ETark Pvt. Ltd. All rights reserved</div>
          </div>
        </section>
      </div>
    </>
  );
}
