import React, {
  useState,
  useEffect
} from 'react';
import axios from 'axios';

var prodapi = process.env.REACT_APP_API;

var prodapi = "https://etarkapi.etark.in/";
// if(process.env.NODE_ENV=="development"){
// prodapi = "http://localhost:1337/";
// }

const DownComp = props => {

  const [isDown, setDown] = useState(true);

  if (isDown) {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const compID = parameters.get('id');

    if (compID) {
      axios.get(`${prodapi}complaints/${compID}/download`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      }).then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/pdf'
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `invoice.pdf`;
        link.click();
        window.open(link.href, "_blank");
        setDown(false);
        // window.history.back();
        // window.close();

      }).catch((err) => {
        console.log(err);
        setDown(false);

        // window.close();
      })
    }
  }

  return (
    <div></div>
  );
};

export default DownComp;
