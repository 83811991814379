import React from "react";
import { Helmet } from 'react-helmet';
import { validateEmail } from "../../lib/helpers";
import mixpanel from "mixpanel-browser";

import PlaySvg from './play.svg';
import './home.css';

export default function DownPage() {
  const [appLink, setLink] = React.useState("https://play.google.com/store/apps/details?id=com.etark.app");

  const goStep = ()=>{
    window.location.href = "#step1";

    var tzone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if(tzone=="Asia/Calcutta"){
      setTimeout(()=>{
        const link = document.createElement('a');
        if(appLink){
          link.href = appLink;
          link.download = 'ETark.apk';
          link.click();
        }

      },500);
    }
  }

  return (
    <>
      <div className="downloadpage">
        <Helmet>
          <title>Download</title>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
        </Helmet>
        <section className="px-12 md:px-24 pt-4">
          <a href="/">
            <img className="h-16" src="/whitelogo.png" />
          </a>
        </section>
        <section>
          <div className="mb:pl-4 md:px-16 flex flex-col mb-12 md:mb-0 md:flex-row md:justify-between items-center">
            <div className="w-full md:h-full px-0 sm:px-6 mb:mt-8 md:p-8 md:w-1/2">
              <div className="text-lg mb:text-sm text-gray-300">
                We are the world's first
              </div>
              <div className="text-gray-100 text-3xl mb:text-lg">
                Instant Complaint Analysis System
              </div>
              <div className="text-lg mb:text-sm text-gray-300 flex h-6 items-center my-2">
                <div className="mr-2">We assure your Phone's Safety</div>
                <img src="./ui/assure.png" className="h-full w-auto" alt=""/>
              </div>
              <div className="playContainer">
                <a href={appLink} target="_blank">
                  <img className="playSvg" src={PlaySvg} alt="play"/>
                </a>
              </div>
            </div>
            <div className="relative mb:w-full mb:mt-0 md:p-12 md:mt-0 herosvgcont">
              <img src="/clay.png"
                className="mb:float-right mb:h-60 md:h-96 herosvg1"/>
            </div>
          </div>
        </section>
        <section id="step1" className="pt-6 text-gray-600 body-font down-section">
          <div className="container px-5 md:px-12 py-24 mx-auto">
            <div className="mb:overflow-scroll w-11/12">
              <div className="flex mb:w-max">
                <div className="viscont mb:w-72 md:w-1/3">
                  <img className="w-full px-2" src="./assets/predoffer.png" alt=""/>
                </div>
                <div className="viscont mb:w-72 md:w-1/3">
                  <img className="w-full px-2" src="./assets/reward.png" alt=""/>
                </div>
                <div className="viscont mb:w-72 md:w-1/3">
                  <img className="w-full px-2" src="./assets/suggpage.png" alt=""/>
                </div>
              </div>
            </div>

          </div>
          <div className="flex flex-col items-center w-full py-2 leading-relaxed">
            <div className="text-gray-300 font-medium text-xl text-center mt-12 mb-8">Follow Us</div>
            <div className="flex items-center">
              <a href="https://www.facebook.com/etarkcompany" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/facebook.png"/>
              </a>
              <a href="http://linkedin.com/company/etark" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/linkedin.png"/>
              </a>
              <a href="https://twitter.com/ETark4" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/twitter.png"/>
              </a>
              <a href="https://instagram.com/etark_official" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/instagram.png"/>
              </a>
            </div>
            <div className="flex mt-8 mb-4 text-gray-400">
              <a href="/privacy">Privacy Policy</a>
            </div>
            <div className="text-gray-400 font-medium text-base text-center mb-4">&copy; Copyright 2021. ETark Pvt. Ltd. All rights reserved</div>
          </div>
        </section>
      </div>
    </>
  );
}
