import React from "react";
import { Helmet } from 'react-helmet';
import { validateEmail } from "../../lib/helpers";
import mixpanel from "mixpanel-browser";

import PlaySvg from './play.svg';
import './home.css';

export default function Home() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [appLink, setLink] = React.useState("https://play.google.com/store/apps/details?id=com.etark.app");

  return (
    <>
      <div className="home">
        <Helmet>
          <title>ETark</title>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
        </Helmet>
        <section className="px-12 md:px-24 pt-4">
          <img className="h-16" src="/logo.png" />
        </section>
        <section>
          <div className="px-4 md:px-16 flex flex-col mb-12 md:mb-0 md:flex-row md:justify-between items-center">
            <div className="w-full md:h-full px-0 sm:px-6 py-8 md:p-8 md:w-1/2">
              <h1 className="text-4xl text-gray-900 font-bold">
                What is ETark?
              </h1>
              <p className="text-lg my-4 text-gray-500">
                We are the world's first platform to instantly analyze your
                complaints against any product/service replete with solutions.
                <br />
                <span className="font-bold">
                  Believe in the power of arguments!
                </span>
              </p>
              <div className="playContainer">
                <a href={appLink} target="_blank">
                  <img className="playSvg" src={PlaySvg} alt="play"/>
                </a>
              </div>
            </div>
            <div className="mt-12 py-12 md:p-12 md:mt-0 herosvgcont">
              <img src="/1.svg"
                className="md:h-96 herosvg1"/>
              <img
                src="/Hero.svg"
                className="herosvg2 absolute z-10 transform -translate-x-14 -translate-y-3/4 md:translate-x-10 md:-translate-y-full h-3/6 md:h-3/6 mb-4"
              />
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="barsvg"
          >
            <path
              fill="#2E3236"
              fillOpacity={1}
              style={{ transform: "translateY(-550px)" }}
              d="M445 756C115.368 690.232 -13.6981 763.805 -253.441 895.666L2868.18 872.975L2716 -0.142107C2632.82 0.121105 2585.47 7.0036 2505.66 30.4313C2276.35 97.7426 2233.72 429.158 2020.48 430.154C1807.24 431.149 1399 168 1347 482C1295 796 774.632 821.768 445 756Z"
            />
          </svg>
        </section>
        <section className="md: text-gray-600 body-font down-section">
          <div className="container px-5 md:px-12 py-24 mx-auto">
            <div className="text-center mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-50 mb-4">
                How can we help you ?
              </h1>
              <div className="flex mt-6 justify-center">
                <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex" />
              </div>
            </div>
            <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
              <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div className="pb-4">
                  <svg
                    width="257"
                    height="221"
                    viewBox="0 0 257 221"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7984 77.8956C-11.6507 97.0695 5.19675 124.542 16.5516 135.881C54.0491 163.457 138.075 219.071 174.2 220.927C219.355 223.246 246.29 169.899 254.212 146.705C262.134 123.511 254.212 102.636 216.978 99.5435C179.745 96.451 146.473 20.6831 109.239 2.90088C72.0057 -14.8814 41.1099 53.9282 11.7984 77.8956Z"
                      fill="#636467"
                      fillOpacity="0.22"
                    />
                  </svg>
                  <img
                    src="/1.png"
                    className="absolute z-10 transform -translate-y-full h-48 sm:h-64 mb-4"
                  />
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-200 text-xl title-font font-medium mb-3">
                    Instant Complaint Analysis
                  </h2>
                  <p className="px-4 leading-relaxed text-left text-gray-400">
                    Get detailed report on your complaint in terms of strength
                    of complaint, chances of winning, time period of resolution
                    & expected compensation.
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div className="pb-4">
                  <svg
                    width="257"
                    height="221"
                    viewBox="0 0 257 221"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7984 77.8956C-11.6507 97.0695 5.19675 124.542 16.5516 135.881C54.0491 163.457 138.075 219.071 174.2 220.927C219.355 223.246 246.29 169.899 254.212 146.705C262.134 123.511 254.212 102.636 216.978 99.5435C179.745 96.451 146.473 20.6831 109.239 2.90088C72.0057 -14.8814 41.1099 53.9282 11.7984 77.8956Z"
                      fill="#636467"
                      fillOpacity="0.22"
                    />
                  </svg>
                  <img
                    src="/2.png"
                    className="absolute z-10 transform -translate-y-full translate-x-1/4 h-48 sm:h-64 mb-4"
                  />
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-200 text-xl title-font font-medium mb-3">
                    Most optimal solution
                  </h2>
                  <p className="px-4 leading-relaxed text-left text-gray-400">
                    Get suggestions for the best course of action for your
                    complaint in terms of cost-benefit analysis
                  </p>
                </div>
              </div>
              <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div className="pb-4">
                  <svg
                    width="257"
                    height="221"
                    viewBox="0 0 257 221"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7984 77.8956C-11.6507 97.0695 5.19675 124.542 16.5516 135.881C54.0491 163.457 138.075 219.071 174.2 220.927C219.355 223.246 246.29 169.899 254.212 146.705C262.134 123.511 254.212 102.636 216.978 99.5435C179.745 96.451 146.473 20.6831 109.239 2.90088C72.0057 -14.8814 41.1099 53.9282 11.7984 77.8956Z"
                      fill="#636467"
                      fillOpacity="0.22"
                    />
                  </svg>
                  <img
                    src="/3.png"
                    className="absolute z-10 transform -translate-y-full translate-x-1/4 h-48 sm:h-64 mb-4"
                  />
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-200 text-xl title-font font-medium mb-3">
                    One-stop window
                  </h2>
                  <p className="px-4 leading-relaxed text-left text-gray-400">
                    Get access to all the resources you need to resolve your
                    consumer complaint without having to go anywhere, not even
                    google !
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full py-2 leading-relaxed">
            <div className="text-gray-300 font-medium text-xl text-center mt-12 mb-8">Follow Us</div>
            <div className="flex items-center">
              <a href="https://www.facebook.com/etarkcompany" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/facebook.png"/>
              </a>
              <a href="http://linkedin.com/company/etark" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/linkedin.png"/>
              </a>
              <a href="https://twitter.com/ETark4" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/twitter.png"/>
              </a>
              <a href="https://instagram.com/etark_official" target="_blank" className="no-underline px-8">
                <img className="h-4 sm:h-6" src="/instagram.png"/>
              </a>
            </div>
            <div className="flex mt-8 mb-4 text-gray-400">
              <a href="/privacy">Privacy Policy</a>
            </div>
            <div className="text-gray-400 font-medium text-base text-center mb-4">&copy; Copyright 2021. ETark Pvt. Ltd. All rights reserved</div>
          </div>
        </section>
      </div>
    </>
  );
}
